import axios from 'axios';

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_KEY}/api`
});

axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('REAL_ESTATE_ACCESS_P_TOKEN');
    // config.headers.Authorization = `Bearer ${token}`;

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;
        if (response.status === 401) {
            localStorage.removeItem('REAL_ESTATE_ACCESS_P_TOKEN');
        }
        throw error;
    }
);

export default axiosClient;
