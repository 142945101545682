// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconNotebook } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconNotebook
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Program = {
    id: 'programs',
    title: 'Programs',
    type: 'group',
    children: [
        {
            id: 'programs-list',
            title: 'Program',
            type: 'collapse',
            icon: icons.IconNotebook,
            children: [
                {
                    id: 'programs-lists',
                    title: 'List',
                    type: 'item',
                    url: '/program/program-list',
                    breadcrumbs: false
                },
                {
                    id: 'add-programs',
                    title: 'Add New',
                    type: 'item',
                    url: '/program/add-program',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Program;
