import { lazy } from 'react';

// project imports
import HomeLayout from 'layout/HomeLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const HomePage = Loadable(lazy(() => import('views/home/HomePage')));

// ==============================|| MAIN ROUTING ||============================== //

const HomeRoutes = {
    path: '/',
    element: <HomeLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />
        }
    ]
};

export default HomeRoutes;
